.bg-mission{
    background-color: #4B5768;
}
.bg-light-gray.help-section.core {
    margin-top: 0;
}
.opportunities .abso{
    position: absolute;
}


/* Mediaquery */
@media screen and ( max-width : 480px ) {
    .help-img.about-img {
        padding-top: 0px !important;
    }
}
@media screen and ( max-width : 1200px ){
    .core .eligibility {
        margin-top: 0;
        padding-top: 0;
    }
    .opportunities .bg-img{
        display: none;
    }
    .opportunities .abso{
        position: initial;
    }
}