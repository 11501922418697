/* help section  */
.pad-top{
    padding-top: 11rem;
}

/* Insurance */
.os-txt{
    margin-top: 3rem;
    padding: 0 5rem 3rem !important;
}

/* Investment  */
.ul-style li{
    margin-bottom: 0.5rem;
}


/* New OS page  */
.invest-banner {
    top: 37%;
    background: rgba(102, 102, 102, 0.20);
    backdrop-filter: blur(4px);
}

/* Media query  */

@media screen and ( min-width : 715px ) and ( max-width : 720px ) {
    .os-txt {
        padding: 0 !important;
    }
}

@media screen and (max-width : 480px) {
    .pad-top {
        padding-top: 7rem;
    }
    .os-txt {
        padding: 0 2rem 3rem !important;
    }
    .os-banner{
        height: 150px !important;
    }
    .invest-banner {
        top: 34%;
    }
    .mobile-width{
        width: 100% !important;
    }
}




