/* Enquiry / Contact  */
.enquiry-contact{
    border-radius: 8px;
    background-image: url('../../images/contact-background.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.contact-img img {
    height: 350px;
}
.contact-svg svg{
    width: 1.4rem;
    height: 1.4rem;
}
.tact svg{
    width: 1.4rem;
}
.enquiry-contact a:hover{
    color: var(--active-color) !important;
}
.piechart canvas {
    height: auto !important;
    width: 100% !important;
}


/* Media query  */
@media screen and ( min-width : 715px ) and ( max-width : 720px ) {
    .piechart.charts{
        padding: 1rem 7rem !important;
    }
    .back-resource-emi .emi-txt{
        padding: 1rem 2rem !important;
    }
    .back-resource-emi .btn.btn-primary{
        margin-bottom: 1.5rem !important;
    }
    .tableStyle h2 {
        font-size: 25px;
    }
}
@media screen and (max-width : 480px) {
    /* Hero image  */
    .educationLoan .headings, .homeLoan .headings{
        top: 105px !important;
    }

    /* For enquiry  */
    .contact-img img {
        height: 250px !important;
    }

    /* For eligibility  */
    .banner-p {
        display: none;
    }
    .loan-top-btn {
        padding: 1rem 0rem !important;
    }
    .headings {
        top: 80px !important ;
        left: 0% !important ;
    }

    /* For Resource calculator */
    .piechart{
        padding: 0 4rem 1.5rem !important;
    }
}

@media screen and (max-width: 300px){
    .headings {
        top: 55px !important;
    }
    .homeLoan .banner-img .headings, .educationLoan .banner-img .headings{
        top: 90px !important;
    }
}

@media screen and (max-width: 285px) {
    .col-md-6.col-12.mt-4.px-lg-5.px-4.border-end {
        padding: 0 10px !important;
        border: none !important;
    }
    .emi-txt {
        padding: 0;
    }
    .piechart {
        padding: 0 2rem 1.5rem !important;
    }
}

