.validateImg {
  margin-left: -15px;
}
.val {
  z-index: 2;
}
.reqDoc-txt {
  padding: 2.5rem 18rem 1rem 4rem;
}
.validate-doc {
  margin-top: -130px;
  background-color: #f0f4ff;
  z-index: 1;
}
.box-doc ul li {
  margin-bottom: 0.25rem;
}
.box-doc {
  position: relative;
  z-index: 30;
}
.validate-doc .reqDoc-txt{
    margin-top: 10rem !important;
}

.z-indexx{
  position: relative;
  z-index: 2;
}

/* Mediaquery */
@media screen and (min-width: 480px) and (max-width: 1100px) {
  .reqDoc-txt {
    padding: 2rem;
  }
}
@media screen and (min-width: 715px) and (max-width: 720px) {
  .validateImg {
    height: auto;
  }
}

@media screen and (max-width: 480px) {
  .validateImg {
    margin-left: 0;
    height: 300px !important;
    margin-top: 1rem;
  }
  .val {
    width: 100% !important;
  }
}
