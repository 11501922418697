/* Statistics or Reference  */
.statistics{
    /* margin-left: 60px !important; */
    position: sticky;
    margin-top: -60px;
}
.bg-light-gray.help-section {
    margin-top: -50px;
}
.box-div{
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 1.2px 8px rgba(0,0,0,.1);
}
.statistics .shops svg, .statistics .calender svg, .statistics .bank svg {
    width: 3rem;
    height: 3rem;
}
.statistics .calender svg{
    stroke: var(--primary-color);
}
.statistics .shops svg, .statistics .bank svg{
    fill: var(--primary-color);
}

/* Eligibility section  */
.eligibility{
    padding-top: 8rem;
    padding-bottom: 3rem;
}
.eligibility .txt{
    padding-left: 5rem;
}
.eligibility .pad {
    padding-right: 10rem;
}


/* Whatsapp connect  */
.whatsapp-connect{
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 100001;
}
/* Bounce whatsapp logo  */
.bounce2 {
    animation: bounce2 2s ease infinite;
}
@keyframes bounce2 {
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
    40% {transform: translateY(-30px);}
    60% {transform: translateY(-15px);}
}



/* MEdiaquery */
@media screen and ( min-width : 715px ) and ( max-width : 720px ) {
    .bg-light-gray.help-section {
        margin-top: -100px;
    }
}

@media screen and ( max-width : 480px ) {

    /* Stats under banner  */
    .statistics {
        margin-left: 0px!important;
        margin-top: -70px;
        transform: scale(0.9);
    }
    .statistics .box-div.col-md-9.col-12.mx-auto.d-flex.justify-content-evenly {
        transform: scaleX(1.15);
    }
    .statistics .shops, .statistics .calender, .statistics .bank {
        text-align: center;
    }
    .statistics .shops svg, .statistics .calender svg, .statistics .bank svg {
        width: 2.5rem;
        height: 2.5rem;
    }
    .statistics .box-div{
        padding: 5px 10px;
    }
    

    /* Home eligibility  */
    .eligibility .pad {
        padding-right: 1rem;
    }
    .eligibility .txt {
        padding-left: 1rem;
    }
    .eligibility {
        padding-top: 7rem;
        margin-top: -7rem;
        padding-bottom: 0rem;
    }
}



