.accordion {
    border: none !important;
}
.accordion__button::before{
    content: none !important;
}
.accordion__button{
    background-color: transparent !important;
    font-weight: 500;
}
.accordion__heading {
    position: relative;
}

/* Plus and minus accordion */
.plus-sign{
    position: absolute;
    top: 35%;
    right: 2%;
}
.plus-sign svg{
    fill: var(--active-color);
}
.minus-sign{
    position: absolute;
    top: 35%;
    right: 2%;
}
.minus-sign svg {
    fill: var(--active-color);
    width: 1.2rem;
    height: 1.2rem;
}

/* Expanded styling  */
.accordion__button[aria-expanded="false"] .minus-sign{
    display: none;
}
.accordion__button[aria-expanded="true"] .minus-sign{
    display: block;
}
.accordion__button[aria-expanded="true"] .plus-sign{
    display: none;
}
.accordion__button[aria-expanded="false"] .plus-sign{
    display: block;
}


.faq{
    background-color: #F6FAFF;
}
.accordion__panel p{
    color: #667085;
}


/* Media Query  */
@media screen and ( max-width : 480px) {
    .accordion__button {
        padding-right: 25px;
    }
    .plus-sign, .minus-sign {
        top: 28% !important;
        right: 1% !important;
    }
}


