.WithStyles\(ForwardRef\(Slider\)\)-thumb-2 {
    box-shadow: 0 1.2px 8px rgba(0,0,0,.1);
}

.emi-part1-img{
    position: relative;
}
#EMI-div-2 .small-text span, #EMI-div-2 .small-text p {
    font-size: 13px !important;
}

#EMI-div-1 .back-light, #EMI-div-2 .back-light {
    border-radius: 20px ;
}

.chartHome canvas {
    height: auto !important;
    width: 100% !important;
}
input[type='radio'] {
    accent-color: #3778A0;
}


/* Mediaquery */
@media screen and ( min-width : 480px ) and ( max-width : 1200px ) {
    .col-md-6.d-none.d-md-block.p-4.emi-part1-img {
        padding: 4.5rem 1rem !important;
    }
}

@media screen and ( min-width : 715px ) and ( max-width : 720px ) {
    .chartHome .charts{
        padding: 1rem 7rem !important;
    }
}

@media screen and ( min-width : 374px ) and ( max-width : 376px ) {
    input.loan-amount.text-center{
        width: 50% !important;
    }
}



