.features-bg{
    background-color: #FAFFF0;
    position: relative;
    margin-top: 5rem;
}

.feature-img {
    padding-left: 6rem;
    padding-right: 6rem;
    position: absolute;
    top: -75px;
}

/* Mediaquery */
@media screen and ( min-width : 1100px ) {
    .feature-img img {
        width: 80%;
        height: auto;
        padding-top: 1rem;
    }
}


@media screen and ( min-width : 480px ) and ( max-width : 1100px ) {
    .feature-img {
        padding-left: 0rem;
        padding-right: 0rem;
    }
    .features-bg .feature-img img{
        width: 120px;
        margin-left: 30px;
    }
    .feature-img.spc{
        padding-top: 1.5rem !important;
    }
}

@media screen and (max-width : 480px) {
    .features-bg {
        margin-top: 1rem;
    }
    .feature-img.spec-img img{
        height: auto;
    }
}

@media screen and (max-width: 300px){
    .feature-img.spec-img img {
        height: 90px;
    }
}

@media screen and ( min-width : 715px ) and ( max-width : 720px ) {
    .feature-img {
        padding-left: 3rem;
        padding-right: 0rem;
        padding-bottom: 1rem;
        position: initial;
    }
    .features-bg.retail .foldP{
        padding-top: 0 !important;
    }
    .features-bg.retail .homeFold{
        margin-top: -5px;
    }
    .fold-style img{
        height: auto !important;
    }
    .fold-style.foldSpecial{
        margin-bottom: 1rem;
    }
}