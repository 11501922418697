/* .WithStyles\(ForwardRef\(Slider\)\)-thumb-2 {
    box-shadow: 0 1.2px 8px rgba(0,0,0,.1);
} */

/* Calculator */
.piechart{
    padding: 1rem 8rem;
}
.back-resource-emi{
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    background: linear-gradient(90deg, #F2FAFF 0%, #F7FCFF 43.38%, #F1F7FF 72.01%, #DDF1FF 100%);
}
.montly-emi-section{
    border-radius: 6px;
    background-color: #e8f5ff;
}
.back-menu{
    border-radius: 6px 6px 0px 0px;
    background: #E1F2FF;
}
.loan-top-btn{
    padding: 1rem 3rem;
}
.loan-top-btn.current{
    background-color: #F6FBFF;
}
/* .back-resource-emi
.back-resource-emi */
input:not([type="range"]):not([type="color"]):not([type="submit"]),  select, textarea {
    border-radius: 8px;
    border: 1px solid #D0D5DD;
}

.emi-txt{
    padding: 0 6rem;
}
.mobWidth{
    width: 25% !important;
}


input[type="range"]{
    accent-color: var(--primary-color);
}





/* Mediaquery */
@media screen and ( min-width : 480px ) and ( max-width : 1200px ) {
    input.loan-amount.text-center {
        width: 50% !important;
    }
    .emi-txt{
        padding: 0;
    }
    .piechart {
        padding: 2rem;
    }
}

@media screen and ( max-width : 480px ) {
    .emi-txt{
        padding: 0 1rem;
    }
    #printable-content tbody, #printable-content td, #printable-content tfoot, #printable-content th, #printable-content thead, #printable-content tr {
        font-size: 14px !important;
    }
    .mobWidth{
        width: 50% !important;
    }
}

@media screen and (max-width: 370px) {
    #printable-content tbody, #printable-content td, #printable-content tfoot, #printable-content th, #printable-content thead, #printable-content tr {
        font-size: 8px !important;
    }
    .montly-emi p {
        font-size: 17px !important;
    }
    .back-resource-emi .btn.btn-primary {
        padding: 0.7rem 1.5rem !important;
    }
    input.loan-amount.text-center {
        width: 50% !important;
    }
}

@media screen and (max-width: 300px){
    input.loan-amount.text-center {
        width: 75% !important;
    }
}



