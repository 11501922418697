.partner-swiper .swiper-button-prev:after, .partner-swiper .swiper-button-next:after {
    font-size: 20px;
    color: var(--active-color);
}

/* Mediaquery */
@media screen and ( max-width : 300px ) {
    .preFolding .foldingFold{
        padding: 0.5rem 1.5rem !important;
    }
}