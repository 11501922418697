.internal-loan-swiper .business-text {
    padding-left: 1rem !important;
    padding-right: 13rem !important;
}
.internal-loan-swiper span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #F4F4F480;
    width: 30px;
    height: 6px;
    border-radius: 20px !important;
}
span.swiper-pagination-bullet {
    width: 30px;
    height: 6px;
    border-radius: 20px !important;
}
.internal-loan-swiper .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
    margin-bottom: -5px;
}
.loan-img img {
    border-radius: 5px;
}
.loan--img {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
    padding-top: 2rem !important;
    padding-bottom: 3.5rem !important;
}


/* Mediaquery */
@media screen and ( min-width : 480px ) and ( max-width : 1100px ) {
    .loan--img{
        padding: 0 !important;
    }
    .internal-loan-swiper .business-text {
        padding: 1rem 0 1rem 1rem !important;
    }
}

@media screen and (max-width : 480px) {
    .internal-loan-swiper .business-text {
        padding-left: 0rem !important;
        padding-right: 0rem !important;
        margin-top: 0rem !important;
        margin-bottom: 1rem !important;
    }
    .swiper-button-prev, .swiper-button-next {
        display: none;
    }
    .loan--img {
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .internal-loan-swiper .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
        margin-bottom: 5px;
    }
}