.internal-slider span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #DDDDDD;
}
.internal-slider .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
    margin-bottom: -13px;
    margin-top: 5px;
    text-align: start;
}
.internal-slider .swiper-wrapper {
    padding-bottom: 12px;
}

.help-img{
    padding-left: 4rem;
    padding-right: 4rem;
}


/* Media query  */
@media screen and (max-width : 480px) {
    .help-img{
        padding-left: 0rem !important;
        padding-right: 0rem !important;
        padding-top: 2rem !important;
    }
}


/* Tab  */
@media screen and ( min-width : 480px ) and ( max-width : 1100px ) {
    .help-img {
        padding-left: 0rem;
        padding-right: 0rem;
    }
}