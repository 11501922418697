.features-bg{
    background-color: #F0F4FF;
    position: relative;
    margin-top: 5rem;
}

.feature-img {
    padding-left: 6rem;
    padding-right: 6rem;
    position: absolute;
    top: -75px;
}


/* Mediaquery */
@media screen and ( max-width : 480px ) {
    .features-bg .feature-img img, .feature-img-2 img{
        padding: 0.8rem;
    }
}


