.vertical-dropdown {
    list-style: none;
    width: 15.8rem;
    position: absolute;
    padding-left: 0;
    margin-top: 10px;
    top: -10px;
    left: 15.8rem;
}
.submenu-item .horizontal-submenu-item {
    display: block;
    width: 100%;
    height: 100%;
}
.vertical-dropdown .submenu-item a {
    color: black;
}

.vertical-dropdown{
    background-color: #ECF8F8;
    border-radius: 5px;
    /* margin-left: -10px; */
    padding-top: 10px;
    padding-bottom: 10px;
}
.vertical-dropdown li{
    padding-left: 20px;
    padding-right: 0px;
    line-height: 2rem;
}


/* Mediaquery */
@media screen and ( min-width : 715px ) and ( max-width : 720px ) {
    .vertical-dropdown {
        top: 30px;
        left: 0rem;
    }
    .vertical-dropdown.unsecured-dropdown {
        top: 70px;
    }
    .vertical-dropdown.unsecured-dropdown li:last-child{
        margin-bottom: 40px !important;
    }
}

@media screen and ( max-width : 480px) {
    .vertical-dropdown {
        width: 100%;
        position: initial;
        padding-left: 10px;
        margin-top: 0px;
        margin-left: 6px;
    }
    .vertical-dropdown li {
        padding-left: 5px;
        padding-right: 0px;
        line-height: 1.3rem;
        font-size: 14px;
    }
}