.banner-img{
    position: relative;
}
.headings{
    position: absolute;
    top: 280px;
    left: 4%;
}
.banner-p{
    padding-right: 17rem;
    padding-left: 17rem;
}
.banner-bottom{
    position: absolute;
    bottom: -103px;
    left: 12%;
    z-index: 1;
}




/* Media query  */
@media screen and ( min-width : 480px ) and ( max-width : 1200px ) {
    .headings {
        top: 130px !important;
        left: 14% !important;
    }
    .banner-p {
        padding-right: 4rem;
        padding-left: 4rem;
    }
    
}

@media screen and ( min-width : 715px ) and ( max-width : 720px ) {
    .headings {
        top: 110px !important;
        left: 13% !important;
    }
    .banner-p {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }
    .headings h1{
        margin-bottom: 1rem !important;
    }
}


@media screen and (max-width : 480px) {
    .banner-bottom h4 {
        font-size: 16px;
    }
    .banner-bottom p {
        font-size: 14px;
    }
    .banner-bottom {
        bottom: -85px;
    }
    .headings.withoutPartner{
        top: 120px !important;
    }
    .about .headings.withoutPartner {
        top: 90px !important;
    }
}