.hr-right{
    text-align: end;
}
/* .footer .foot-contact svg {
    fill: var(--active-color);
} */
.telephone svg{
    width: 1.7em;
    height: 1.7em;
}
.mail svg{
    vertical-align: top;
    width: 2em;
    height: 2em;
}
.location svg{
    vertical-align: bottom;
    width: 3.3em;
    height: 2em;
}
.footer .foot-contact a{
    color: var(--active-color);
}
.footer .foot-contact a:hover{
    color: var(--active-color);
}
.footer .foot-menu ul{
    padding-left: 0;
}
.footer .foot-menu li{
    list-style: none;
}
.footer {
    box-shadow: 0 -1.2px 8px rgba(0,0,0,.1);
}
.light-text {
    color: #4B5768;
    opacity: 0.45;
}

.foot-menu a.light-color:hover {
    color: var(--active-color);
}

.link-opacity-100-hover:hover {
    opacity: 1 !important;
}


/* Footer dropdown css  */
.vertical-footer {
    width: 15.8rem;
    position: absolute;
    top: -8px;
    left: 15rem;
    background-color: #F7F7FF;
}
ul.vertical-footer .submenu-item.horizontal-submenu-item {
    padding-left: 10px;
}
ul.vertical-footer li:last-child{
    padding-bottom: 10px !important;
}
ul.vertical-footer li:first-child{
    padding-top: 0px !important;
}
.vertical-footer.unsecured{
    top: 24px;
}



/* Mediaquery */
@media screen and (min-width : 480px) and ( max-width : 1100px ) {
    .location svg {
        width: 7em;
    }
}

@media screen and ( min-width : 715px ) and ( max-width : 720px ) {
    .location svg {
        width: 3em;
    }
    .foot-menu {
        overflow-x: auto;
    }
}

@media screen and ( max-width : 480px ) {
    .foot-menu h5 {
        font-size: 20px !important;
    }
    .foot-menu ul {
        font-size: 14px !important;
    }
    .foot-menu{
        overflow-x: auto;
    }
}

@media screen and (max-width: 370px) {
    .location svg {
        width: 6em;
    }
}