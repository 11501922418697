.collateral {
    margin-top: -110px;
    position: sticky;
    z-index: 2;
}
.special-collateral{
    margin-top: -70px;
}




@media screen and (max-width : 480px) {
    .collateral {
        margin-top: -130px;
    }
}