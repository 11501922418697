.partner-swiper-internal .swiper-button-prev:after, .partner-swiper-internal .swiper-button-next:after {
    font-size: 15px;
    color: var(--active-color);
}



@media screen and ( min-width : 715px ) and ( max-width : 720px ){
    .dualFold .foldSize{
        padding: 1rem 2rem !important;
    }
}