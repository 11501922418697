@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


:root{
    --plusFont : 'Plus Jakarta Sans', sans-serif;
    --workFont : 'Work Sans', sans-serif;
    --active-color : #3778A0;
    --primary-color : #3778A0;
    --light-color : #777777;
    --light-green : #3778A0;
    --dark-blue : #0A2046;
    --cream-color : #FCFCF0;
    --calculator-bg : #F0F4FF;
    --light-gray : #667085;
    --menu-bg : #F9FFFF;
}
body{
    font-family: var(--plusFont) !important;
}
.bg-light-gray{
    background-color: #E3EBF2;
}
.back-light{
    background-color: #F2F2F2;
}
.primary-color{
    color: var(--active-color);
}
.light-color{
    color: var(--light-color);
}
.bg-light-green{
    background-color: var(--light-green);
}
.bg-dark-blue{
    background-color: var(--dark-blue);
}
.bg-cream{
    background-color: var(--cream-color);
}
.bg-internal{
    background-color: #F7FAFD;
}
.bg-light-voilet{
    background-color: #F6F8FF;
}
.back-gray{
    background-color: #E4E4E4;
}
.gray-color{
    color: var(--light-gray);
}
.bg-insu{
    background-color: #F7FAFD;
}

.active{
    color: blue !important;
}
button {
    border: none;
    background: transparent;
}
.txt-color{
    color: var(--primary-color);
}



.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active, .btn.disabled, .btn:disabled, fieldset:disabled .btn {
    background-color: var(--primary-color) !important;
}
.btn.btn-primary {
    border-radius: 50px;
    background-color: var(--primary-color);
    border: none !important;
}
.btn.btn-primary:hover{
    background-color: var(--primary-color) !important;
}
.btn.btn-secondary {
    background-color: white !important;
    color: var(--primary-color) !important;
    border-color: white !important;
    border-radius: 50px !important;
}
.btn.btn-secondary:hover {
    background-color: #ededed !important;
    border-color: #ededed !important;
}



/* Input number text arrows */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.fs-7{
    font-size: 14px;
}
/* ul{
    margin-bottom: 0px !important;
} */

/* box shadow  */
.bx-shdow{
    box-shadow: 3px 6.2px 8px rgba(0,0,0,.2) !important;
}




/* ---------------------------------------- Media Query ---------------------------------------- */


/* Tablet  */
@media screen and (min-width : 480px) and (max-width : 1200px) {
    /* For enquiry  */
    .contact-img img {
        height: 200px !important;
    }
}



