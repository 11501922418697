.firstP{
    text-align: center;
}
.insideP{
    background-color: #E7EEFF;
    width: 30%;
    margin-left: 50px;
    border-radius: 50% 50%;
    height: 45px;
    padding-top: 10px;
    font-size: 16px;
    font-weight: 500;
}


/* Mediaquery */
@media screen and (min-width : 480px) and ( max-width : 1100px ) {
    .pros-6 img{
        margin-right: 0px !important;
    }
}

@media screen and ( min-width : 715px ) and ( max-width : 720px ) {
    .insideP{
        width: 17%;
        margin-left: 110px;
    }
}

@media screen and ( max-width : 480px) {
    .pros-1 img, .pros-2 img, .pros-3 img, .pros-5 img, .pros-6 img, .pros-4 img {
        width: 100%;
        padding: 0rem 2rem;
    }
    .pros-1, .pros-2, .pros-3, .pros-5, .pros-6, .pros-4 {
        padding: 0.5rem 1rem;
    }
    .pros-2 img, .pros-6 img, .pros-5 img {
        margin: 0 !important;
    }
    
}

@media screen and (max-width: 370px) {
    .insideP {
        width: 50px;
        margin-left: 40px;
    }
    .pros-1 img, .pros-2 img, .pros-3 img, .pros-5 img, .pros-6 img, .pros-4 img {
        padding: 0rem 1rem;
    }
}

@media screen and (max-width: 300px){
    .insideP {
        margin-left: 20px;
    }
}

