.businessLoan-swiper .business-text {
    padding-left: 1rem !important;
    padding-right: 13rem !important;
}
.businessLoan-swiper span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #F4F4F480;
    width: 30px;
    height: 6px;
    border-radius: 20px !important;
}
span.swiper-pagination-bullet {
    width: 30px;
    height: 6px;
    border-radius: 20px !important;
}
.businessLoan-swiper .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
    margin-bottom: -5px;
}
.businessLoan-swiper .swiper-button-prev:after, .businessLoan-swiper .swiper-button-next:after {
    font-size: 40px;
    color: white;
}
.loan-img img {
    border-radius: 5px;
}

/* Media Query  */

@media screen and (max-width : 480px) {
    .businessLoan-swiper .business-text {
        padding-left: 0rem !important;
        padding-right: 0rem !important;
    }
    .swiper-button-prev, .swiper-button-next {
        display: none;
    }
    .businessLoan-swiper .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
        margin-bottom: 5px;
    }
}

@media screen and ( min-width : 480px ) and ( max-width : 1200px ) {
    .businessLoan-swiper .business-text {
        padding: 0 !important;
    }
    .col-md-6.p-md-5.p-2.mt-4.mt-md-0.loan-img {
        padding: 0 !important;
        padding-right: 1rem !important;
        padding-top: 5rem !important;
    }
    .businessLoan-swiper .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
        margin-bottom: 15px;
    }
    .homeBusiness .loan-img{
        padding: 2rem 1rem !important;
    }
    .business-text .special-txt{
        font-size: 14px;
    }
    .business-text .special-h1{
        font-size: 25px;
    }
}