.credit-card .features-bg{
    background-color: #F0F4FF;
    position: relative;
    margin-top: 5rem;
}

.credit-card .feature-img-1 {
    padding-left: 10rem;
    padding-right: 8rem;
    position: absolute;
    top: -145px;
}

.credit-card .feature-img-2 {
    padding-left: 9rem;
    padding-right: 9rem;
}

.reward-img {
    padding: 3rem 7rem 3rem 3rem;
}
.reward-txt {
    padding: 4rem 2rem 4rem 4rem;
}

.feature-img.special-feature{
    padding: 0 8rem 0 3.5rem;
    top: -90px;
}

.feature-img-cc{
    padding-left: 6rem;
    padding-right: 6rem;
    position: absolute;
    top: -75px;
}
.other-feature{
    position: relative;
    margin-top: 5rem;
}
.feature-img-cc.special-feature {
    margin-top: -10px;
}


/* Mediaquery */
@media screen and ( min-width : 480px ) and ( max-width : 1200px ){
    .credit-card .feature-img-1 {
        padding-left: 4rem;
        padding-right: 3rem;
        top: -135px;
    }
    .credit-card .feature-padd{
        padding-top: 1rem !important;
    }
    .credit-card .feature-img-2 {
        padding-left: 4rem;
        padding-right: 3rem;
    }
    .feature-img-cc {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .feature-img-cc.special-feature{
        padding-left: 0rem !important;
    }
    .reward-img {
        padding: 0;
    }
    .reward-txt {
        padding: 2rem 0.5rem;
    }
}

@media screen and ( max-width : 480px ) {
    .reward-txt {
        padding: 0;
    }
    .reward-img {
        padding: 0 1rem 2.5rem;
    }
    .credit-card .feature-img-1{
        position: initial;
        padding: 0 2rem;
        margin-top: 1rem;
    }
    .credit-card .feature-img-2 {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0 !important;
        position: initial;
    }
    
    
    .insure .feature-img-cc{
        position: initial;
        padding: 0 2rem;
        margin-top: 1rem;
    }
    .insure .feature-img-cc{
        padding-top: 0px !important;
    }
    /* .insure h3 {
        font-size: 20px;
    } */
    .insure .feature-img-cc img{
        width: 100%;
    }
    .feature-img.special-feature {
        padding: 1.4rem 0 0 0rem !important;
    }
    
    .other-feature {
        margin-top: 0;
    }
    .feature-img-cc.special-feature {
        padding: 0 5px;
        margin-top: 0rem;
    }
}

@media screen and ( max-width : 767px )  {
    .credit-card .feature-img-2 {
        padding-left: 2rem;
    }
}

@media screen and ( min-width : 715px ) and ( max-width : 720px ) {
    .credit-card .feature-img-1 {
        padding-left: 5rem;
        padding-right: 5rem;
        position: initial;
    }
    .credit-card .feature-img-2 {
        padding-left: 4rem;
        padding-right: 5rem;
    }
    .other-feature {
        position: relative;
        margin-top: 1rem;
    }
    .reward-txt, .reward-img {
        padding: 0;
        margin-bottom: 1rem;
    }
    .feature-img-cc {
        padding-left: 4rem;
        padding-right: 2rem;
        position: initial;
    }
    .feature-img-cc.special-feature {
        margin-top: -20px;
        padding: 3rem 4rem 0 !important;
    }
    .feature-img-cc.special-feature img{
        width: 100% !important;
    }
    .insure .fet-txt{
        padding-top: 1rem !important;
    }
}



