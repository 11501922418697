/* Estimate  */
.estimates{
    position: relative;
}
.estimate-1 {
    padding: 40px 100px;
}
.estimate-2{
    position: absolute;
    top: 27%;
    left: 30%;
}
.estimate-txt{
    padding-right: 15rem;
}



/* Media query  */
@media screen and (min-width : 480px) and ( max-width : 1100px ) {
    .estimate-1 {
        padding: 1rem;
    }
    .estimate-txt {
        padding-right: 0;
        padding-bottom: 3rem;
    }
}

@media screen and ( min-width : 715px ) and ( max-width : 720px ) {
    .estimate-1 {
        padding: 2rem 4rem;
    }
}

@media screen and ( max-width : 480px) {
    .estimate-txt {
        padding: 2rem 1rem !important;
    }
    .estimate-txt a {
        font-size: 14px;
    }
    .estimate-1 {
        padding: 2rem 0rem;
    }
    .estimate-txt p {
        padding-bottom: 1rem;
    }
}

@media screen and ( max-width : 320px ) {
    .estimate-txt a {
        font-size: 12px;
    }
}