




@media screen and (max-width : 480px) {
    /* Balance transfer  */
    .reqDoc-txt {
        padding: 1rem 1rem 4rem 1rem !important;
    }
    
    .feature-img {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
        position: initial !important;
        height: 6rem;
    }
}

@media screen and ( max-width : 380px ) {
    .spc.feature-img {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
    .feature-img {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
}