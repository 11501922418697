a.brand-logo.navbar-brand.nav-link img {
    width: 200px;
}
.navbar-toggler-icon {
    background-image: url('../../images/hamburger.png') !important;
}
.header.botstrap{
    box-shadow: 0 1.2px 8px rgba(0,0,0,.1);
}
.xyz .active{
    color: var(--primary-color) !important;
}

/* Mobile styles  */
.navbar{
    --bs-navbar-toggler-focus-width: 0 !important;
}
div#offcanvasNavbar-expand-lg {
    width: 70%;
    top: 4.3rem;
    background-color: var(--menu-bg) !important;
}
.offcanvas.offcanvas-end.offcanvas-toggling {
    width: 70%;
    top: 4.71rem;
}
.header{
    background-color: var(--menu-bg);
}

/* Mediaquery */
@media screen and ( min-width : 767px ) and ( max-width : 992px ) {
    a.brand-logo.navbar-brand.nav-link img {
        width: 100px;
    }
}
@media screen and ( min-width : 715px ) and ( max-width : 720px ) {
    .nav-item {
        height: auto;
    }
    div#offcanvasNavbar-expand-lg {
        width: 55%;
    }
    .offcanvas.offcanvas-end.offcanvas-toggling {
        width: 55%;
    }
}
@media screen and ( min-width : 992px ) and ( max-width : 1200px ) {
    a.brand-logo.navbar-brand.nav-link img {
        width: 180px;
    }
    li.nav-item.pe-2.me-lg-4.me-1.mt-2.text-decoration-none {
        margin-right: 0.5rem !important;
    }
}

@media screen and ( max-width : 767px ) {
    a.brand-logo.navbar-brand.nav-link img {
        width: 150px;
    }
}

@media screen and ( max-width : 480px ) {
    a.brand-logo.navbar-brand.nav-link img {
        width: 130px;
    }
}

@media screen and ( max-width : 300px ) {
    div#offcanvasNavbar-expand-lg {
        width: 75%;
    }
    .offcanvas.offcanvas-end.offcanvas-toggling {
        width: 75%;
    }
}