.slideshow{
    position: relative;
}
.banner-box{
    position: absolute;
    top: 17.5rem;
    width: 100% !important;
    text-align: center;
    color: white;
}
.banner-box p {
    padding: 0 20rem;
}
.slideshow button{
    z-index: 1001;
}


/* Media query  */
/* Mobile  */
@media screen and ( max-width : 480px ) {
    .banner-box {
        top: 2rem;
        transform: scale(0.8);
    }
    .banner-box p {
        padding: 0;
    }
    .slideshow button{
        margin: -25px -40px 0px !important;
        top: 40% !important;
    }

    .banner-box p{
        font-size: 13px;
    }
    .banner-box h1{
        font-size: 22px;
    }
}

/* Mobile web  */
@media screen and (min-width : 480px) and ( max-width : 1100px ) {
    .banner-box {
        position: absolute;
        top: 8.5rem;
    }
    .banner-box p {
        padding: 0 10rem;
    }
}

@media screen and ( min-width : 715px ) and ( max-width : 720px ) {
    .banner-box{
        padding: 0 2rem;
        top: 7rem;
    }
    .banner-box p {
        padding: 0 5rem;
    }
}






