.submenu-item:hover{
    background-color: #e4f6f6;
}

.sme-dropdown{
    list-style: none;
    width: 15.8rem;
    position: absolute;
    padding-left: 0;
    margin-top: 10px;
    top: 70px;
    /* top: 40px; */
    z-index: 1001;
}
.sme-dropdown .submenu-item a{
    color: black;
}
.sme-dropdown .submenu-item:hover{
    color: var(--active-color);
}
.sme-dropdown .submenu-item{
    display: block;
    width: 100%;
    height: 100%;
}
.sme-dropdown.clicked{
    display: none !important;
}

.nav-items .submenu-div {
    color : black !important;
    width: 200%;
    background-size: 13rem;
}

.sme-dropdown{
    background-color: #ECF8F8;
    border-radius: 5px;
    margin-left: -15px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.sme-dropdown li{
    padding-left: 15px;
    padding-right: 5px;
    line-height: 2rem;
}


/* Mediaquery */
@media screen and ( min-width : 715px ) and ( max-width : 720px ) {
    .sme-dropdown{
        top: 90px;
        padding-top: 0px;
    }
    .sme-dropdown.retail-dropdown {
        top: 120px;
    }
    .sme-dropdown.resource-dropdown {
        top: 150px;
    }
}

@media screen and ( max-width : 480px ) {
    .sme-dropdown {
        width: 110%;
        font-size: 14px;
        margin-left: -10px;
        top: 95px;
        margin-top: 0;
        position: initial;
        padding-top: 0;
    }
    .nav-items .submenu-div {
        width: 100%;
    }
    ul.nav-items.d-md-flex.flex-wrap {
        padding: 0;
        margin: 0;
    }
    .sme-dropdown li {
        padding-left: 5px;
        padding-right: 5px;
        line-height: 1.5rem;
    }

    /* Media menus  */
    .retail-dropdown{
        top: 125px;
    }
    .resource-dropdown{
        top: 155px;
    }
}