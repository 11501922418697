/* Form  */
.retail-form{
    padding-top: 11rem;
}


/* Mediaquery */
@media screen and ( max-width : 480px ) {
    .retail-form {
        padding-top: 8rem;
    }
    .retail .feature-img {
        height: 3.2rem;
    }
    .retail .feature-img.spec-img {
        height: 3.2rem;
        /* padding-left: 2rem !important;
        padding-right: 2rem !important; */
    }
}

