a.navbar-logo {
    width: 18%;
}
ul.nav-items.d-flex.flex-wrap li:last-child{
    padding-right: 0px !important;
}
.nav-items .nav-item{
    list-style: none;
    position: relative;
}
.nav-items .nav-item a{
    color : black;
}
.nav-items .nav-item a:hover{
    color : var(--active-color) !important;
}
.nav-item{
    display: flex;
    align-items: center;
    height: 4rem;
    /* height: 3.5rem; */
}
.header.navbaar{
    box-shadow: 0 1.2px 8px rgba(0,0,0,.1);
}

/* For mobile dropdown  */
.navbar-toggler {
    border: none !important;
    font-size: 20px !important;
}
.navbar-toggler svg {
    vertical-align: top;
    font-size: 2rem;
}
.navbar-toggler:focus {
    box-shadow: none !important;
}
ul.mob-dropdown {
    position: absolute;
    left: auto;
    z-index: 100;
    right: 0;
    background-color: white;
    padding-left: 0;
    padding-bottom: 10px;
    border-radius: 5px;
    width: 65%;
}
ul.mob-dropdown li {
    padding: 1rem 0;
    padding-left: 10px;
}
ul.mob-dropdown {
    height: 770px;
    margin-right: -11px;
    margin-top: 5px;
    overflow: hidden;
}

.hidebg{
    /* opacity: 0.5; */
    overflow: hidden;
}




/* For mobile  */
@media screen and ( max-width : 480px ) {
    .nav-item {
        height: 1.5rem;
        display: contents;
    }
    a.navbar-logo {
        width: 35%;
    }
}

/* For tablet  */
@media screen and (min-width : 480px) and ( max-width : 1100px ) {
    a.navbar-logo {
        width: 20%;
    }
    ul.nav-items.d-md-flex.flex-wrap {
        padding: 0;
        margin: 0 !important;
    }
    li.nav-item.pe-2.me-md-4.mt-2.text-decoration-none {
        margin: 0 !important;
    }
    li.nav-item.pe-2.me-md-4.mt-2.text-decoration-none:not(:last-child) {
        padding-right: 15px !important;
    }
    .justify-content-end.flex-grow-1.pe-4.navbar-nav {
        padding: 0 !important;
    }
}


